<template>
  <SubNav
    class="subnav-position"
    :selected="'details'"
    :buildingId="buildingId"
  />

  <main>
    <div v-if="building">
      <form @submit.prevent="handleSave" class="edit-form">
        <div class="float-end">
          <router-link :to="{ name: 'Buildings' }"
            ><h4>&times;</h4></router-link
          >
        </div>
        <div class="form-outline mb-4">
          <label>Building Name</label>
          <input
            type="text"
            required
            v-model="building.name"
            class="form-control"
          />
        </div>
        <div class="form-outline mb-4">
          <label>Building Type</label>
          <input
            type="text"
            required
            v-model="building.type"
            class="form-control"
          />
        </div>
        <div class="form-outline mb-4">
          <label>Maximum Usable Square Feet</label>
          <input
            type="number"
            required
            v-model="building.maxUsqft"
            class="form-control"
          />
        </div>
        <div class="form-outline mb-4">
          <label>Building Address</label>
          <input
            type="text"
            required
            v-model="building.address"
            class="form-control"
          />
        </div>
        <div class="form-outline mb-4">
          <label>Leasing Phone Number</label>
          <input
            type="text"
            required
            v-model="building.leasePhone"
            class="form-control"
          />
        </div>
        <div class="form-outline mb-4">
          <label>Support Email Address</label>
          <input
            type="text"
            v-model="building.supportEmail"
            class="form-control"
            @blur="validateEmail"
            required
          />
          <div class="error">{{ emailError }}</div>
        </div>
        <div class="form-outline mb-4">
          <label>Description</label>
          <textarea
            v-model="building.description"
            class="form-control long-text"
          >
          </textarea>
        </div>
        <div class="form-outline mb-4">
          <label>Location Summary</label>
          <input
            type="text"
            v-model="building.locationSummary"
            class="form-control"
          />
        </div>
        <div class="form-outline mb-4">
          <label>Location Detail</label>
          <textarea v-model="building.location" class="form-control long-text">
          </textarea>
        </div>
        <div class="form-outline mb-4">
          <label>About Us Building Summary</label>
          <textarea
            v-model="building.aboutUsSummary"
            class="form-control long-text"
          >
          </textarea>
        </div>
        <div class="form-outline mb-4">
          <label>About Us Building Details</label>
          <textarea
            v-model="building.aboutUsDetail"
            class="form-control long-text"
          >
          </textarea>
        </div>
        <div class="form-outline mb-4">
          <label>About Us Owner Details</label>
          <textarea
            v-model="building.aboutUsOwner"
            class="form-control long-text"
          >
          </textarea>
        </div>
        <div class="row">
          <!-- Logo Upload -->
          <div class="form-outline mb-4 col-12 col-sm-6">
            <div v-if="building.logoURLThumb" class="">
              <img :src="building.logoURLThumb" />
            </div>
            <div v-else class="building-thumb"></div>
            <div>
              <label>Building Logo</label>
            </div>
            <div class="error">{{ errorLogo }}</div>
            <div v-if="isPendingLogo">
              <button class="btn btn-secondary" disabled>
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Saving...</span>
                </div>
              </button>
            </div>
            <div v-else>
              <input type="file" @change="handleLogo" />
            </div>
          </div>
          <div class="form-outline mb-4 col-12 col-sm-6">
            <!-- Favicon Upload -->
            <div v-if="building.faviconURL" class="">
              <p>Favicon Loaded</p>
            </div>
            <div v-else class="building-thumb"></div>
            <div>
              <label
                >Building Website Favicon (16x16 .ico file
                <a
                  href="https://redketchup.io/favicon-generator"
                  target="_blank"
                  >generator</a
                >)</label
              >
            </div>
            <div class="error">{{ errorFavicon }}</div>
            <div v-if="isPendingFavicon">
              <button class="btn btn-secondary" disabled>
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Saving...</span>
                </div>
              </button>
            </div>
            <div v-else>
              <input type="file" @change="handleFavicon" />
            </div>
          </div>
        </div>

        <div class="row">
          <!-- Image1 Upload -->
          <div class="form-outline mb-4 col-12 col-sm-6 col-lg-3">
            <div v-if="building.image1URLThumb" class="">
              <img :src="building.image1URLThumb" />
            </div>
            <div v-else class="building-thumb"></div>
            <div>
              <label>Primary Building Image (Min 200x200)</label>
            </div>
            <div class="error">{{ errorImage1 }}</div>
            <div v-if="isPendingImage1">
              <button class="btn btn-secondary" disabled>
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Saving...</span>
                </div>
              </button>
            </div>
            <div v-else>
              <input type="file" @change="handleImage1" />
            </div>
          </div>
          <!-- Image2 Upload -->
          <div class="form-outline mb-4 col-12 col-sm-6 col-lg-3">
            <div v-if="building.image2URLThumb" class="">
              <img :src="building.image2URLThumb" />
            </div>
            <div v-else class="building-thumb"></div>
            <div>
              <label>Secondary Building Image (Min 200x200)</label>
            </div>
            <div class="error">{{ errorImage2 }}</div>
            <div v-if="isPendingImage2">
              <button class="btn btn-secondary" disabled>
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Saving...</span>
                </div>
              </button>
            </div>
            <div v-else>
              <input type="file" @change="handleImage2" />
            </div>
          </div>
          <!-- Image3 Upload -->
          <div class="form-outline mb-4 col-12 col-sm-6 col-lg-3">
            <div v-if="building.image3URLThumb" class="">
              <img :src="building.image3URLThumb" />
            </div>
            <div v-else class="building-thumb"></div>
            <div>
              <label>Top Location Building Image (Min 200x200)</label>
            </div>
            <div class="error">{{ errorImage3 }}</div>
            <div v-if="isPendingImage3">
              <button class="btn btn-secondary" disabled>
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Saving...</span>
                </div>
              </button>
            </div>
            <div v-else>
              <input type="file" @change="handleImage3" />
            </div>
          </div>
          <!-- Image4 Upload -->
          <div class="form-outline mb-4 col-12 col-sm-6 col-lg-3">
            <div v-if="building.image4URLThumb" class="">
              <img :src="building.image4URLThumb" />
            </div>
            <div v-else class="building-thumb"></div>
            <div>
              <label>Bottom Location Building Image (Min 200x200)</label>
            </div>
            <div class="error">{{ errorImage4 }}</div>
            <div v-if="isPendingImage4">
              <button class="btn btn-secondary" disabled>
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Saving...</span>
                </div>
              </button>
            </div>
            <div v-else>
              <input type="file" @change="handleImage4" />
            </div>
          </div>
        </div>
        <div class="error">{{ errorForm }}</div>
        <div v-if="!isPending" class="row text-center">
          <div class="col">
            <button class="btn btn-primary">Save</button>
          </div>
          <div class="col">
            <router-link :to="{ name: 'Buildings' }" class="btn btn-secondary">
              Cancel
            </router-link>
          </div>
          <div class="col">
            <span class="btn btn-link text-danger" @click="handleDelete">
              Delete Building
            </span>
          </div>
        </div>
        <div v-else class="row text-center">
          <div class="col">
            <button class="btn btn-secondary" disabled>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Saving...</span>
              </div>
            </button>
          </div>
        </div>
      </form>
      <div class="form-outline m-5">
        <label>Building URL (www.buildingname.com)</label>
        <div class="error">{{ errorURL }}</div>
        <div class="row">
          <div class="col-9">
            <input type="text" v-model="building.url" class="form-control" />
          </div>
          <div class="col-3">
            <div v-if="isURLPending" class="spinner-border" role="status">
              <span class="visually-hidden">Updating...</span>
            </div>
            <div v-else>
              <span class="btn btn-primary" @click="handleUpdateURL">
                Update URL
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import getDocument from "@/composables/getDocument";
import useDocument from "@/composables/useDocument";
import useFunctions from "@/composables/useFunctions";
import useStorage from "@/composables/useStorage";
import { useRouter } from "vue-router";
import { timestamp } from "@/firebase/config";
import { ref } from "@vue/reactivity";
import SubNav from "@/components/navigation/SubNav.vue";

export default {
  props: ["buildingId"],
  components: { SubNav },
  setup(props) {
    const isPending = ref(false);
    const emailError = ref(null);
    const validEmail = ref(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const validateEmail = () => {
      emailError.value = "";
      if (!validEmail.value.test(building.value.supportEmail)) {
        emailError.value = "Invalid Support Email Address.";
      }
    };
    const errorForm = ref(null);
    const router = useRouter();
    const {
      error: errorURL,
      isPending: isURLPending,
      updateURL
    } = useFunctions();
    const { error: errorDoc, document: building } = getDocument(
      "buildings",
      props.buildingId
    );

    const {
      error: errorUpdate,
      deleteDoc: deleteBuilding,
      updateDoc: updateBuilding
    } = useDocument("buildings", props.buildingId);

    const {
      error: errorRentRoll,
      deleteDoc: deleteRentRoll,
      deleteSubCollection: deleteLeaseList
    } = useDocument("rentrolls", props.buildingId);

    const {
      error: errorImage1,
      isImagePending: isPendingImage1,
      url: image1URL,
      filePath: image1FilePath,
      urlThumb: image1URLThumb,
      filePathThumb: image1FilePathThumb,
      urlMedium: image1URLMedium,
      filePathMedium: image1FilePathMedium,
      uploadImageResize: uploadImage1Resize,
      deleteImage: deleteImage1
    } = useStorage();

    const {
      error: errorImage2,
      isImagePending: isPendingImage2,
      url: image2URL,
      filePath: image2FilePath,
      urlThumb: image2URLThumb,
      filePathThumb: image2FilePathThumb,
      urlMedium: image2URLMedium,
      filePathMedium: image2FilePathMedium,
      uploadImageResize: uploadImage2Resize,
      deleteImage: deleteImage2
    } = useStorage();

    const {
      error: errorImage3,
      isImagePending: isPendingImage3,
      url: image3URL,
      filePath: image3FilePath,
      urlThumb: image3URLThumb,
      filePathThumb: image3FilePathThumb,
      urlMedium: image3URLMedium,
      filePathMedium: image3FilePathMedium,
      uploadImageResize: uploadImage3Resize,
      deleteImage: deleteImage3
    } = useStorage();

    const {
      error: errorImage4,
      isImagePending: isPendingImage4,
      url: image4URL,
      filePath: image4FilePath,
      urlThumb: image4URLThumb,
      filePathThumb: image4FilePathThumb,
      urlMedium: image4URLMedium,
      filePathMedium: image4FilePathMedium,
      uploadImageResize: uploadImage4Resize,
      deleteImage: deleteImage4
    } = useStorage();

    const {
      error: errorLogo,
      isImagePending: isPendingLogo,
      url: logoURL,
      filePath: logoFilePath,
      urlThumb: logoURLThumb,
      filePathThumb: logoFilePathThumb,
      urlMedium: logoURLMedium,
      filePathMedium: logoFilePathMedium,
      uploadImageResize: uploadLogoResize,
      deleteImage: deleteLogo
    } = useStorage();

    const {
      error: errorFavicon,
      isImagePending: isPendingFavicon,
      url: faviconURL,
      filePath: faviconFilePath,
      uploadImage: uploadFavicon,
      deleteImage: deleteFavicon
    } = useStorage();

    const handleUpdateURL = async () => {
      try {
        await updateURL(props.buildingId, building.value.url);
        if (errorUpdate.value) {
          console.log(`Error updating URL: ${errorUpdate.value}`);
        }
      } catch (err) {
        errorUpdate.value = err.message;
      }
    };

    const saveDetails = async () => {
      await updateBuilding({
        name: building.value.name,
        type: building.value.type,
        maxUsqft: building.value.maxUsqft ? building.value.maxUsqft : 0,
        description: building.value.description
          ? building.value.description
          : "",
        locationSummary: building.value.locationSummary
          ? building.value.locationSummary
          : "",
        location: building.value.location ? building.value.location : "",
        aboutUsSummary: building.value.aboutUsSummary
          ? building.value.aboutUsSummary
          : "",
        aboutUsDetail: building.value.aboutUsDetail
          ? building.value.aboutUsDetail
          : "",
        aboutUsOwner: building.value.aboutUsOwner
          ? building.value.aboutUsOwner
          : "",
        address: building.value.address ? building.value.address : "",
        leasePhone: building.value.leasePhone ? building.value.leasePhone : "",
        supportEmail: building.value.supportEmail,
        logoFilePath: building.value.logoFilePath
          ? building.value.logoFilePath
          : "",
        logoURL: building.value.logoURL ? building.value.logoURL : "",
        logoFilePathThumb: building.value.logoFilePathThumb
          ? building.value.logoFilePathThumb
          : "",
        logoURLThumb: building.value.logoURLThumb
          ? building.value.logoURLThumb
          : "",
        logoFilePathMedium: building.value.logoFilePathMedium
          ? building.value.logoFilePathMedium
          : "",
        logoURLMedium: building.value.logoURLMedium
          ? building.value.logoURLMedium
          : "",
        faviconFilePath: building.value.faviconFilePath
          ? building.value.faviconFilePath
          : "",
        faviconURL: building.value.faviconURL ? building.value.faviconURL : "",
        image1FilePath: building.value.image1FilePath
          ? building.value.image1FilePath
          : "",
        image1URL: building.value.image1URL ? building.value.image1URL : "",
        image1FilePathThumb: building.value.image1FilePathThumb
          ? building.value.image1FilePathThumb
          : "",
        image1URLThumb: building.value.image1URLThumb
          ? building.value.image1URLThumb
          : "",
        image1FilePathMedium: building.value.image1FilePathMedium
          ? building.value.image1FilePathMedium
          : "",
        image1URLMedium: building.value.image1URLMedium
          ? building.value.image1URLMedium
          : "",

        image2FilePath: building.value.image2FilePath
          ? building.value.image2FilePath
          : "",
        image2URL: building.value.image2URL ? building.value.image2URL : "",
        image2FilePathThumb: building.value.image2FilePathThumb
          ? building.value.image2FilePathThumb
          : "",
        image2URLThumb: building.value.image2URLThumb
          ? building.value.image2URLThumb
          : "",
        image2FilePathMedium: building.value.image2FilePathMedium
          ? building.value.image2FilePathMedium
          : "",
        image2URLMedium: building.value.image2URLMedium
          ? building.value.image2URLMedium
          : "",

        image3FilePath: building.value.image3FilePath
          ? building.value.image3FilePath
          : "",
        image3URL: building.value.image3URL ? building.value.image3URL : "",
        image3FilePathThumb: building.value.image3FilePathThumb
          ? building.value.image3FilePathThumb
          : "",
        image3URLThumb: building.value.image3URLThumb
          ? building.value.image3URLThumb
          : "",
        image3FilePathMedium: building.value.image3FilePathMedium
          ? building.value.image3FilePathMedium
          : "",
        image3URLMedium: building.value.image3URLMedium
          ? building.value.image3URLMedium
          : "",

        image4FilePath: building.value.image4FilePath
          ? building.value.image4FilePath
          : "",
        image4URL: building.value.image4URL ? building.value.image4URL : "",
        image4FilePathThumb: building.value.image4FilePathThumb
          ? building.value.image4FilePathThumb
          : "",
        image4URLThumb: building.value.image4URLThumb
          ? building.value.image4URLThumb
          : "",
        image4FilePathMedium: building.value.image4FilePathMedium
          ? building.value.image4FilePathMedium
          : "",
        image4URLMedium: building.value.image4URLMedium
          ? building.value.image4URLMedium
          : "",
        editedAt: timestamp()
      });
      if (errorUpdate.value) {
        errorForm.value = errorUpdate.value;
      }
    };

    const handleSave = async () => {
      isPending.value = true;
      errorForm.value = null;
      await saveDetails();
      isPending.value = false;
    };

    const handleDelete = async () => {
      isPending.value = true;
      errorForm.value = null;

      //Delete the leaseList subcollection
      await deleteLeaseList("leaseList");
      if (errorRentRoll.value) {
        errorForm.value = errorRentRoll.value;
      } else {
        await deleteRentRoll();
        if (errorRentRoll.value) {
          errorForm.value.errorRentRoll.value;
        } else {
          await deleteLogoImages();
          await deleteFaviconImages();
          await deleteImage1Images();
          await deleteImage2Images();
          await deleteImage3Images();
          await deleteImage4Images();

          await deleteBuilding();
          isPending.value = false;
          if (errorUpdate.value) {
            errorForm.value = errorUpdate.value;
          } else {
            router.push({ name: "Buildings" });
          }
        }
      }
      isPending.value = false;
    };

    const deleteLogoImages = async () => {
      //Delete the existing images so our resize doesn't break
      if (building.value.logoFilePath) {
        await deleteLogo(building.value.logoFilePath);
        building.value.logoFilePath = "";
        building.value.logoURL = "";
      }
      if (building.value.logoFilePathThumb) {
        await deleteLogo(building.value.logoFilePathThumb);
        building.value.logoFilePathThumb = "";
        building.value.logoURLThumb = "";
      }
      if (building.value.logoFilePathMedium) {
        await deleteLogo(building.value.logoFilePathMedium);
        building.value.logoFilePathMedium = "";
        building.value.logoURLMedium = "";
      }
    };

    const deleteFaviconImages = async () => {
      //Delete the existing images so our resize doesn't break
      if (building.value.faviconFilePath) {
        await deleteFavicon(building.value.faviconFilePath);
        building.value.faviconFilePath = "";
        building.value.faviconURL = "";
      }
    };

    const deleteImage1Images = async () => {
      if (building.value.image1FilePath) {
        await deleteImage1(building.value.image1FilePath);
        building.value.image1FilePath = "";
        building.value.image1URL = "";
      }
      if (building.value.image1FilePathThumb) {
        await deleteImage1(building.value.image1FilePathThumb);
        building.value.image1FilePathThumb = "";
        building.value.image1URLThumb = "";
      }
      if (building.value.image1FilePathMedium) {
        await deleteImage1(building.value.image1FilePathMedium);
        building.value.image1FilePathMedium = "";
        building.value.image1URLMedium = "";
      }
    };

    const deleteImage2Images = async () => {
      if (building.value.image2FilePath) {
        await deleteImage2(building.value.image2FilePath);
        building.value.image2FilePath = "";
        building.value.image2URL = "";
      }
      if (building.value.image2FilePathThumb) {
        await deleteImage2(building.value.image2FilePathThumb);
        building.value.image2FilePathThumb = "";
        building.value.image2URLThumb = "";
      }
      if (building.value.image2FilePathMedium) {
        await deleteImage2(building.value.image2FilePathMedium);
        building.value.image2FilePathMedium = "";
        building.value.image2URLMedium = "";
      }
    };

    const deleteImage3Images = async () => {
      if (building.value.image3FilePath) {
        await deleteImage3(building.value.image3FilePath);
        building.value.image3FilePath = "";
        building.value.image3URL = "";
      }
      if (building.value.image3FilePathThumb) {
        await deleteImage3(building.value.image3FilePathThumb);
        building.value.image3FilePathThumb = "";
        building.value.image3URLThumb = "";
      }
      if (building.value.image3FilePathMedium) {
        await deleteImage3(building.value.image3FilePathMedium);
        building.value.image3FilePathMedium = "";
        building.value.image3URLMedium = "";
      }
    };

    const deleteImage4Images = async () => {
      if (building.value.image4FilePath) {
        await deleteImage4(building.value.image4FilePath);
        building.value.image4FilePath = "";
        building.value.image4URL = "";
      }
      if (building.value.image4FilePathThumb) {
        await deleteImage4(building.value.image4FilePathThumb);
        building.value.image4FilePathThumb = "";
        building.value.image4URLThumb = "";
      }
      if (building.value.image4FilePathMedium) {
        await deleteImage4(building.value.image4FilePathMedium);
        building.value.image4FilePathMedium = "";
        building.value.image4URLMedium = "";
      }
    };

    // allowed file types
    const types = ["image/png", "image/jpeg"];

    // allowed favicon types
    const faviconTypes = ["image/vnd.microsoft.icon", "image/x-image"];

    const handleImage1 = async e => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        //Delete the existing images so our resize doesn't break
        await deleteImage1Images();
        await uploadImage1Resize(
          `buildings/${props.buildingId}`,
          "image1",
          selected,
          building.value.managerId
        );
        if (!errorImage1.value) {
          building.value.image1FilePath = image1FilePath.value;
          building.value.image1URL = image1URL.value;
          building.value.image1FilePathThumb = image1FilePathThumb.value;
          building.value.image1URLThumb = image1URLThumb.value;
          building.value.image1FilePathMedium = image1FilePathMedium.value;
          building.value.image1URLMedium = image1URLMedium.value;
          await saveDetails();
          if (errorUpdate.value) {
            errorForm.value = errorUpdate.value;
          }
        }
      } else {
        errorImage1.value = "Please select an image file (png or jpeg)";
      }
    };

    const handleImage2 = async e => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        //Delete the existing images so our resize doesn't break
        await deleteImage2Images();
        await uploadImage2Resize(
          `buildings/${props.buildingId}`,
          "image2",
          selected,
          building.value.managerId
        );
        if (!errorImage2.value) {
          building.value.image2FilePath = image2FilePath.value;
          building.value.image2URL = image2URL.value;
          building.value.image2FilePathThumb = image2FilePathThumb.value;
          building.value.image2URLThumb = image2URLThumb.value;
          building.value.image2FilePathMedium = image2FilePathMedium.value;
          building.value.image2URLMedium = image2URLMedium.value;
          await saveDetails();
          if (errorUpdate.value) {
            errorForm.value = errorUpdate.value;
          }
        }
      } else {
        errorImage2.value = "Please select an image file (png or jpeg)";
      }
    };

    const handleImage3 = async e => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        //Delete the existing images so our resize doesn't break
        await deleteImage3Images();
        await uploadImage3Resize(
          `buildings/${props.buildingId}`,
          "image3",
          selected,
          building.value.managerId
        );
        if (!errorImage3.value) {
          building.value.image3FilePath = image3FilePath.value;
          building.value.image3URL = image3URL.value;
          building.value.image3FilePathThumb = image3FilePathThumb.value;
          building.value.image3URLThumb = image3URLThumb.value;
          building.value.image3FilePathMedium = image3FilePathMedium.value;
          building.value.image3URLMedium = image3URLMedium.value;
          await saveDetails();
          if (errorUpdate.value) {
            errorForm.value = errorUpdate.value;
          }
        }
      } else {
        errorImage3.value = "Please select an image file (png or jpeg)";
      }
      isPendingImage3.value = false;
    };

    const handleImage4 = async e => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        //Delete the existing images so our resize doesn't break
        await deleteImage4Images();
        await uploadImage4Resize(
          `buildings/${props.buildingId}`,
          "image4",
          selected,
          building.value.managerId
        );
        if (!errorImage4.value) {
          building.value.image4FilePath = image4FilePath.value;
          building.value.image4URL = image4URL.value;
          building.value.image4FilePathThumb = image4FilePathThumb.value;
          building.value.image4URLThumb = image4URLThumb.value;
          building.value.image4FilePathMedium = image4FilePathMedium.value;
          building.value.image4URLMedium = image4URLMedium.value;
          await saveDetails();
          if (errorUpdate.value) {
            errorForm.value = errorUpdate.value;
          }
        }
      } else {
        errorImage4.value = "Please select an image file (png or jpeg)";
      }
    };

    const handleLogo = async e => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        await deleteLogoImages();
        await uploadLogoResize(
          `buildings/${props.buildingId}`,
          "logo",
          selected,
          building.value.managerId
        );

        if (!errorLogo.value) {
          building.value.logoFilePath = logoFilePath.value;
          building.value.logoURL = logoURL.value;
          building.value.logoFilePathThumb = logoFilePathThumb.value;
          building.value.logoURLThumb = logoURLThumb.value;
          building.value.logoFilePathMedium = logoFilePathMedium.value;
          building.value.logoURLMedium = logoURLMedium.value;
          await saveDetails();
        }
      } else {
        errorLogo.value = "Please select an image file (png or jpeg)";
      }
    };

    const handleFavicon = async e => {
      const selected = e.target.files[0];
      console.log(`Favicon Mime Type: ${selected.type}`);
      if (selected && faviconTypes.includes(selected.type)) {
        await deleteFaviconImages();
        await uploadFavicon(
          `buildings/${props.buildingId}`,
          "favicon",
          selected,
          building.value.managerId
        );

        if (!errorFavicon.value) {
          building.value.faviconFilePath = faviconFilePath.value;
          building.value.faviconURL = faviconURL.value;
          await saveDetails();
        }
      } else {
        errorFavicon.value = "Please select a favicon (.ico file)";
      }
    };

    return {
      validateEmail,
      emailError,
      isPending,
      isPendingLogo,
      isPendingFavicon,
      isPendingImage1,
      isPendingImage2,
      isPendingImage3,
      isPendingImage4,
      errorDoc,
      errorForm,
      building,
      handleSave,
      handleDelete,
      handleUpdateURL,
      handleImage1,
      handleImage2,
      handleImage3,
      handleImage4,
      handleLogo,
      handleFavicon,
      errorImage1,
      errorImage2,
      errorImage3,
      errorImage4,
      errorLogo,
      errorFavicon,
      errorURL,
      isURLPending
    };
  }
};
</script>

<style>
</style>